import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DirectBillService } from '../direct-bill/direct-bill.service';
import { AgencyManagementSystemService } from '../agency-management-system/agency-management-system.service';
import { User } from './interfaces/user';
import { Settings } from '../web-apps/interfaces/settings';
import { WebAppsService } from '../web-apps/web-apps.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: User = null;
  userObservable: Observable<User> = null;

  constructor(
    private http: HttpClient,
    private directBillService: DirectBillService,
    private amsService: AgencyManagementSystemService,
    private webAppsService: WebAppsService
  ) {

  }

  /**
   * Get a combined user from web apps, agency
   * management system, and direct bill.
   */
  getUser(): Observable<User> {
    if (this.user) {
      return of(this.user);
    }
    if (this.userObservable) {
      return this.userObservable;
    }
    this.userObservable = forkJoin(
      [
        this.webAppsService.getUser(),
        this.directBillService.getUser(),
        this.amsService.getAgencies(),
        this.amsService.getSystemRoles(),
        this.amsService.getGlassAgencies()
      ]
    ).pipe(
      map(val => {
        this.user = {
          admin: val[0].admin,
          agencies: val[2],
          broker: val[0].broker,
          canManageCommissionStatements: val[1].agencies.filter(a => { return a.canManageCommissionStatements }).length > 0,
          canViewCommissionStatements: val[1].agencies.filter(a => { return a.canViewCommissionStatements }).length > 0,
          canViewVinLookup: val[0].canViewVinLookup,
          directBillAgencies: val[1].agencies,
          homeOffice: val[0].homeOffice,
          settings: val[0].settings,
          glassAgencies: val[4],
          glassAccess: val[3].filter(s => s.system === 'GLS').length > 0,
          agencyPowerUser: val[3].filter(s => s.system === 'NICO Website' && s.role === 'Agency Power User').length > 0,
          homeOfficePowerUser: val[3].filter(s => s.system === 'NICO Website' && s.role === 'Home Office Power User').length > 0
        };
        return this.user;
      }),
      share()
    );
    return this.userObservable;
  }
  /**
   * Wrapper to save the user's settings and update
   * the cached user.
   * @param settings
   */
  saveSettings(settings: Settings): Observable<Settings> {
    return this.webAppsService.saveSettings(settings).pipe(
      map((response: Settings) => {
        if (this.user) {
          this.user.settings = response;
        }
        return response;
      })
    );
  }
}
