import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { Email } from './interfaces/email';
import { environment } from '../../../../../environments/environment';
import { Underwriter } from './interfaces/underwriter';
import { Department } from './interfaces/department';
import { FactType } from './interfaces/fact-type';
import { Alert } from './interfaces/alert';
import { Application } from './interfaces/application';
import { Bulletin } from './interfaces/bulletin';
import { Guide } from './interfaces/guide';
import { Question } from '../custom/interfaces/question';
import { RecentlyBoundQuestions } from '../../data/recently-bound-questions';
import { Policy } from './interfaces/policy';
import { TrainingRequest } from './interfaces/training-request';
import { UserTopClasses } from './interfaces/user-top-classes';
import { TopClassesQuestions } from '../../data/top-classes-questions';
import { VehicleDetails } from './interfaces/vehicle-details';
import { SoftwareDownload } from './interfaces/software-download';
import { CommercialAutoManual } from './interfaces/commercial-auto-manual';
import { Company } from './interfaces/company';
import { RateType } from './interfaces/rate-type';
import { EditCommercialAutoManualCommand } from './interfaces/edit-commercial-auto-manual-command';
import { map } from 'rxjs/operators';
import { CreateGuideCommand } from './interfaces/create-guide-command';
import { EditGuideCommand } from './interfaces/edit-guide-command';
import { GuideType } from './interfaces/guide-type';
import { CreateCommercialAutoManualCommand } from './interfaces/create-commercial-auto-manual-command';
import { User } from './interfaces/user';
import { Link } from './interfaces/link';
import { Setting } from './interfaces/setting';
import { Settings } from './interfaces/settings';
import { EditBulletinCommand } from './interfaces/edit-bulletin-command';
import { CreateBulletinCommand } from './interfaces/create-bulletin-command';
import { UserAlert } from './interfaces/user-alert';
import { CustomStamp } from './interfaces/custom-stamp';
import { ApplicationStamp } from './interfaces/application-stamp'
import { Topic } from './interfaces/topic';
import { Article } from './interfaces/article';
import { CreateArticleCommand } from './interfaces/create-article-command';
import { EditArticleCommand } from './interfaces/edit_article_command';
import { CreateApplicationCommand } from './interfaces/create-application-command';
import { EditApplicationCommand } from './interfaces/edit-application-command';
import { ApplicationState } from './interfaces/application-state';
import { UserInfo } from './interfaces/user-info';
import { ReportsAdminUserInfo } from './interfaces/reports-admin-user';
import { ReportsAdminUserInfoDisplay } from './interfaces/reports-admin-user';
import { CreateMarketingMaterialCommand } from './interfaces/create-marketing-material-command';
import { EditMarketingMaterialCommand } from './interfaces/edit-marketing-material-command';
import { MarketingMaterial } from '../../../write-more/pages/marketing-materials-page/marketing-material';
import { LaunchDarklyService } from '../../../../services/launch-darkly/launch-darkly.service';

@Injectable({ providedIn: 'root' })
export class WebAppsService {
  private api: string = '';
  private pdfOptions = {
    responseType: 'blob' as 'json',
    headers: new HttpHeaders().set('Content-Type', 'application/pdf')
  };
  private jpgOptions = {
    responseType: 'blob' as 'json',
    headers: new HttpHeaders().set('Content-Type', 'image/jpeg')
  };

  constructor( private http: HttpClient, private featureFlags: LaunchDarklyService ) { this.api = environment.webAppsAPI; }

  /***********************************************************************
   * Vehicle Details
   * Section
   **********************************************************************/
  /**
   * Get a list of vehicles that match the vin number.
   * @param vin
   */
  getVehicleDetails(vin: string): Observable<VehicleDetails[]> {
    return this.http.get<VehicleDetails[]>(`${this.api}vehicle-details/${vin}`);
  }
  /***********************************************************************
   * User
   * Section
   **********************************************************************/
  getUser(): Observable<User> {
    return this.http.get<User>(`${this.api}user`);
  }
  /**
   * Get the options that a user can select from for the links that are displayed on their homescreen.
   */
  getOptionalHomescreenLinkOptions(): Observable<Link[]> {
    return this.http.get<Link[]>(`${this.api}user/available-home-page-links`);
  }
  /**
   * Get an array of roles that a user can select.
   */
  getRoleOptions(): Observable<Setting[]> {
    return this.http.get<Setting[]>(`${this.api}user/available-roles`);
  }
  /**
   * Get an array of interests that a user can choose from.
   */
  getInterestOptions(): Observable<Setting[]> {
    return this.http.get<Setting[]>(`${this.api}user/available-interests`);
  }
  /**
   * Save the user's settings.
   * @param settings
   */
  saveSettings(settings: Settings): Observable<Settings> {
    return this.http.put<Settings>(`${this.api}user/settings`, settings);
  }
  /**
   * Get an array of users that exist on the system.
   */
  getAllUsers(): Observable<ReportsAdminUserInfo[]>{
    return this.http.get<ReportsAdminUserInfo[]>(`${this.api}user/reports-admin-all`);
  }
  /**
   * Saves changes made to the admin reports users.
   * @returns
   */
  saveUsers(users: ReportsAdminUserInfo[]): Observable<boolean>{
    return users.length > 0 
     ? this.http.put<boolean>(`${this.api}user/reports-admin-save`, users) 
     : of(true);
  }
  /***********************************************************************
   * Departments
   * Section
   **********************************************************************/

  /***********************************************************************
   * FactTypes
   * Section
   **********************************************************************/

  /***********************************************************************
   * Images
   * Section
   **********************************************************************/

  /***********************************************************************
   * Underwriters
   * Section
   **********************************************************************/
  /**
   * Get a list of all of the underwriters.
   */
  getUnderwriters(): Observable<Underwriter[]> {
    return this.http.get<Underwriter[]>(`${this.api}underwriters`);
  }
  /**
   * Get the details of a single underwriter.
   * @param id
   */
  getUnderwriter(id: number): Observable<Underwriter> {
    return this.http.get<Underwriter>(`${this.api}underwriters/${id}`);
  }
  /**
   * Get a list of the underwriting departments.
   * This is useful for filtering the underwriters based on department.
   */
  getUnderwriterDepartments(): Observable<Department[]> {
    return this.http.get<Department[]>(`${this.api}underwriter-departments`);
  }
  /**
   * Update departments with the new values.
   * @param departments
   */
  updateUnderwriterDepartments(departments: Department[]) {
    return this.http.put<Department[]>(`${this.api}underwriter-departments`, departments);
  }
  /**
   * Get a list of the underwriting fact types. This is useful for editing underwriters.
   */
  getUnderwriterFactTypes(): Observable<FactType[]> {
    return this.http.get<FactType[]>(`${this.api}underwriter-fact-types`);
  }
  /**
   * Get an image of the underwriter.
   * @param icon True if you only want an icon sized image.
   */
  getUnderwriterImage(id: number, thumbnail: boolean = false): Observable<any> {
    return this.http.get( `${this.api}underwriters/${id}${thumbnail ? '?simple=true' : ''}`, this.jpgOptions);
  }
  /**
   * Delete the underwriter with the given id.
   * @param id
   */
  deleteUnderwriter(id: number): Observable<Underwriter> {
    return this.http.delete<Underwriter>(`${this.api}underwriters/${id}`);
  }
  /**
   * Add an underwriter.
   * @param underwriter The underwriter to add.
   */
  addUnderwriter(underwriter: Underwriter, image: File): Observable<Underwriter> {
    const uploadData = new FormData();
    uploadData.append('underwriter[title]', underwriter.title);
    uploadData.append('underwriter[displayPosition]', underwriter.displayPosition.toString());
    for (let i = 0; i < underwriter.departments.length; i++) {
      uploadData.append(`underwriter[departments][${i}][id]`, underwriter.departments[i].id.toString());
    }
    uploadData.append('underwriter[firstName]', underwriter.firstName);
    uploadData.append('underwriter[middleName]', underwriter.middleName);
    uploadData.append('underwriter[lastName]', underwriter.lastName);
    uploadData.append('underwriter[email]', underwriter.email);
    uploadData.append('underwriter[phoneNumber]', underwriter.phoneNumber);
    uploadData.append('underwriter[cpcu]', 'false');
    for (let i = 0; i < underwriter.facts.length; i++) {
      uploadData.append(`underwriter[facts][${i}][value]`, underwriter.facts[i].value);
      uploadData.append(`underwriter[facts][${i}][type][id]`, underwriter.facts[i].type.id.toString());
    }
    uploadData.append('image', image);
    return this.http.post<Underwriter>(`${this.api}underwriters`, uploadData);
  }
  /**
   * Update an underwriter.
   * @param underwriter The underwriter to update.
   */
  updateUnderwriter(underwriter: Underwriter, image: File): Observable<void> {
    const uploadData = new FormData();
    uploadData.append('underwriter[id]', underwriter.id.toString());
    uploadData.append('underwriter[title]', underwriter.title);
    uploadData.append('underwriter[displayPosition]', underwriter.displayPosition.toString());
    for (let i = 0; i < underwriter.departments.length; i++) {
      uploadData.append(`underwriter[departments][${i}][id]`, underwriter.departments[i].id.toString());
    }
    uploadData.append('underwriter[firstName]', underwriter.firstName);
    uploadData.append('underwriter[middleName]', underwriter.middleName);
    uploadData.append('underwriter[lastName]', underwriter.lastName);
    uploadData.append('underwriter[email]', underwriter.email);
    uploadData.append('underwriter[phoneNumber]', underwriter.phoneNumber);
    uploadData.append('underwriter[cpcu]', 'false');
    for (let i = 0; i < underwriter.facts.length; i++) {
      uploadData.append(`underwriter[facts][${i}][value]`, underwriter.facts[i].value);
      uploadData.append(`underwriter[facts][${i}][type][id]`, underwriter.facts[i].type.id.toString());
    }
    uploadData.append('image', image);
    return this.http.put<void>(`${this.api}underwriters/${underwriter.id}`, uploadData);
  }
  /**
   * Update all of the underwriters.
   * @param underwriters
   */
  updateUnderwriters(underwriters: Underwriter[]): Observable<Underwriter[]> {
    return this.http.put<Underwriter[]>(`${this.api}underwriters`, underwriters);
  }
  /***********************************************************************
   * TopClasses
   * Section
   **********************************************************************/
  /**
   * Get all of the top classes for this user.
   */
  getTopClasses(): Observable<UserTopClasses> {
    return this.http.get<UserTopClasses>(`${this.api}top-classes`);
  }
  /**
   * Get questions users may have about top classes.
   */
  getTopClassesQuestions(): Observable<Question[]> {
    return of(TopClassesQuestions);
  }
  /***********************************************************************
   * SoftwareDownloads
   * Section
   **********************************************************************/
  /**
   * Get information about a specific application including the download url.
   * @param applicationName The name of the application.
   */
  getSoftwareDownload(applicationName: string): Observable<SoftwareDownload> {
    return this.http.get<SoftwareDownload>(`${this.api}software-downloads/${applicationName}`);
  }
  /**
   * Get instructions related to downloaded software
   * @param filename name of application
   */
  downloadSoftwareInstructions(filename: string): Observable<Blob> {
    return this.http.get<Blob>(`${this.api}software-downloads/${filename}/pdf`, this.pdfOptions);
  }
  /**
   * Download the application as an MSI.
   * @param applicationname
   */
  downloadSoftwareMsi(applicationname: string): Observable<Blob> {
    return this.http.get<Blob>(`${this.api}software-downloads/${applicationname}/msi`, { responseType: 'blob' as 'json' });
  }
  /***********************************************************************
   * RecentlyBound
   * Section
   **********************************************************************/
  /**
   * Get an array of all the recently bound accounts.
   */
  getRecentlyBounds(): Observable<Policy[]> {
    return this.http.get<Policy[]>(`${this.api}recently-bound`);
  }
  /**
   * Get the date that the recently bound accounts were updated.
   */
  getRecentlyBoundsDateUpdated(): Observable<Date> {
    return this.http.get<Date>(`${this.api}recently-bound/date-updated`);
  }
  /**
   * Get questions users may have about recently bound.
   */
  getRecentlyBoundQuestions(): Observable<Question[]> {
    return of(RecentlyBoundQuestions);
  }
  /***********************************************************************
   * RateTypes
   * Section
   **********************************************************************/
  /**
   * Get a list of rate types.
   */
  getRateTypes(): Observable<RateType[]> {
    return this.http.get<RateType[]>(`${this.api}rate-types`);
  }
  /***********************************************************************
   * NicoNews
   * Section
   **********************************************************************/
  /**
   * Get all of the NICO News for the marketing page.
   */
  getNicoNews(): Observable<MarketingMaterial[]> {
    return this.http.get<MarketingMaterial[]>(`${this.api}nico-news`);
  }
  /**
   * Get a nico news by id.
   * @param id
   */
  getNicoNewsItem(id: number): Observable<MarketingMaterial> {
    return this.http.get<MarketingMaterial>(`${this.api}nico-news/${id}`);
  }
  /**
   * Get a nico news icon by id.
   * @param id
   */
  getNicoNewsIcon(id: number): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(`${this.api}nico-news/${id}/icon`, { observe: 'response', responseType: 'blob' as 'json' });
  }
  /**
   * Get a nico news pdf by id.
   * @param id
   */
  getNicoNewsPdf(id: number, withHeaders: boolean): Observable<any> {
    if (withHeaders)
      return this.http.get<Blob>(`${this.api}nico-news/${id}/pdf`, { observe: 'response', responseType: 'blob' as 'json' });
    return this.http.get<Blob>(`${this.api}nico-news/${id}/pdf`, { responseType: 'blob' as 'json' })
  }
  /**
   * Create a new nico news.
   * @param command
   */
  createNicoNews(command: CreateMarketingMaterialCommand): Observable<void> {
    const uploadData = new FormData();
    uploadData.append('name', command.name);
    uploadData.append('date', (command.date.getMonth() + 1) + '/' + command.date.getDate() + '/' + command.date.getFullYear());
    uploadData.append('showToBrokers', command.showToBrokers.toString());
    uploadData.append('pdfFile', command.pdfFile);
    uploadData.append('thumbnailFile', command.thumbnailFile);
   
    return this.http.post<void>(`${this.api}nico-news`, uploadData);
  }
  /**
  * Edit an existing nico news.
  * @param command
  */
  editNicoNews(command: EditMarketingMaterialCommand): Observable<void> {
    const uploadData = new FormData();
    uploadData.append('name', command.name);
    uploadData.append('date', (command.date.getMonth() + 1) + '/' + command.date.getDate() + '/' + command.date.getFullYear());
    uploadData.append('showToBrokers', command.showToBrokers.toString());
    uploadData.append('pdfFile', command.pdfFile);
    uploadData.append('thumbnailFile', command.thumbnailFile);

    return this.http.put<void>(`${this.api}nico-news/${command.id}`, uploadData);
  }
  /**
 * Delete a nico news by the id.
 * @param id
 */
  deleteNicoNews(id: number): Observable<void> {
    return this.http.delete<void>(`${this.api}nico-news/${id}`);
  }
  /***********************************************************************
   * Topics
   * Section
   **********************************************************************/
  /**
   * Get all of the Topics for the training materials page.
   */
  getTopics():Observable<Topic[]>{
    return this.http.get<Topic[]>(`${this.api}topics`);
  }
  
  getArticles(): Observable<Article[]>{
    return this.http.get<Article[]>(`${this.api}articles`);
  }
  
  getArticlePdf(articleid: number): Observable<Blob> {
    return this.http.get<Blob>(`${this.api}articles/${articleid}/pdf`, { responseType: 'blob' as 'json' });
  }

  /**
   * Create a new Article.
   * @param command
   */
  createArticle(command: CreateArticleCommand): Observable<void> {
    const uploadData = new FormData();
    uploadData.append('title', command.title);
    uploadData.append('startDate', (command.startDate.getMonth() + 1) + '/' + command.startDate.getDate() + '/' + command.startDate.getFullYear());
    uploadData.append('endDate', (command.endDate.getMonth() + 1) + '/' + command.endDate.getDate() + '/' + command.endDate.getFullYear());
    uploadData.append('file', command.document)
   
    return this.http.post<void>(`${this.api}articles/${command.topicId}`, uploadData);
   
  }
  /**
  * Edit an existing article.
  * @param command
  */
  editArticle(command: EditArticleCommand): Observable<void> {
    const uploadData = new FormData();
    uploadData.append('title', command.title);
    uploadData.append('startDate', (command.startDate.getMonth() + 1) + '/' + command.startDate.getDate() + '/' + command.startDate.getFullYear());
    uploadData.append('endDate', (command.endDate.getMonth() + 1) + '/' + command.endDate.getDate() + '/' + command.endDate.getFullYear());
    uploadData.append('file', command.document)


    return this.http.put<void>(`${this.api}articles/update/${command.articleId}`, uploadData);
  }
  /**
 * Delete a article by the id.
 * @param id
 */
  deleteArticle(id: number): Observable<void> {
    return this.http.put<void>(`${this.api}articles/delete/${id}`, null);
  }

  /**
 * Get a article by the id.
 * @param id
 */
  getArticle(id: number): Observable<Article> {
    return this.http.get<Article>(`${this.api}articles/${id}`).pipe(
      map(b => {
        b.startDate = new Date(b.startDate);
        b.endDate = new Date(b.endDate);
        b.topicId = b.topicId;
        return b;
      })
    );
  }
  
  /***********************************************************************
   * Guides
   * Section
   **********************************************************************/
  /**
   * Get a list of all of the available guides/manuals.
   */
  getGuides(): Observable<Guide[]> {
    return this.http.get<Guide[]>(`${this.api}guides`);
  }
  /**
   * Get a guide based on the id.
   * @param id
   */
  getGuide(id: number): Observable<Guide> {
    return this.http.get<Guide>(`${this.api}guides/${id}`).pipe(map(g => { g.shipDate = new Date(g.shipDate); return g; }));
  }
  /**
   * Create a guide.
   * @param command
   */
  createGuide(command: CreateGuideCommand): Observable<void> {
    const uploadData = new FormData();
    uploadData.append('File', command.file);
    uploadData.append('Name', command.name);
    uploadData.append('ShipDate',
      (command.shipDate.getMonth() + 1) + '/' + command.shipDate.getDate() + '/' + command.shipDate.getFullYear());
    uploadData.append('Shown', command.shown.toString());
    uploadData.append('Type', command.type.toString());
    return this.http.post<void>(`${this.api}guides`, uploadData);
  }
  /**
   * Edit a guide.
   * @param command
   */
  editGuide(command: EditGuideCommand): Observable<void> {
    const uploadData = new FormData();
    // uploadData.append('Id', command.id.toString());
    uploadData.append('File', command.file);
    uploadData.append('Name', command.name);
    uploadData.append('ShipDate',
      (command.shipDate.getMonth() + 1) + '/' + command.shipDate.getDate() + '/' + command.shipDate.getFullYear());
    uploadData.append('Shown', command.shown.toString());
    uploadData.append('Type', command.type.toString());
    return this.http.put<void>(`${this.api}guides/${command.id}`, uploadData);
  }
  /**
   * Get a guide as a PDF.
   * @param id
   */
  getGuidePdf(id: number): Observable<Blob> {
    return this.http.get<Blob>(`${this.api}guides/${id}`, this.pdfOptions);
  }
  /**
   * Delete a guide given the id.
   * @param id
   */
  deleteGuide(id: number): Observable<void> {
    return this.http.delete<void>(`${this.api}guides/${id}`);
  }
  /***********************************************************************
   * CommercialAutoManuals
   * Section
   **********************************************************************/
  /**
   * Get a list of valid guide types.
   */
  getGuideTypes(): Observable<GuideType[]> {
    return this.http.get<GuideType[]>(`${this.api}guide-types`);
  }
  /***********************************************************************
   * CommercialAutoManuals
   * Section
   **********************************************************************/
  getCommercialAutoManuals(): Observable<CommercialAutoManual[]> {
    return this.http.get<CommercialAutoManual[]>(`${this.api}commercial-auto-manuals`);
  }
  /**
   * Get a commercial auto manual by the id.
   * @param id
   */
  getCommercialAutoManual(id: number): Observable<CommercialAutoManual> {
    return this.http.get<CommercialAutoManual>(`${this.api}commercial-auto-manuals/${id}`).pipe(
      map(cam => {
        cam.shipDate = new Date(cam.shipDate);
        return cam;
      })
    );
  }
  /**
   * Create a commercial auto manual.
   * @param command
   */
  createCommercialAutoManaul(command: CreateCommercialAutoManualCommand): Observable<void> {
    const uploadData = new FormData();
    uploadData.append('ShipDate',
      (command.shipDate.getMonth() + 1) + '/' + command.shipDate.getDate() + '/' + command.shipDate.getFullYear());
    uploadData.append('Company', command.company.toString());
    uploadData.append('State', command.state);
    uploadData.append('RateType', command.rateType.toString());
    uploadData.append('Version', command.version);
    uploadData.append('Shown', command.shown.toString());
    uploadData.append('File', command.file);
    return this.http.post<void>(`${this.api}commercial-auto-manuals`, uploadData);
  }
  /**
   * Edit an existing commercial auto manual.
   * @param command
   */
  editCommercialAutoManual(command: EditCommercialAutoManualCommand): Observable<void> {
    const uploadData = new FormData();
    uploadData.append('File', command.file);
    uploadData.append('Id', command.id.toString());
    uploadData.append('ShipDate',
      (command.shipDate.getMonth() + 1) + '/' + command.shipDate.getDate() + '/' + command.shipDate.getFullYear());
    uploadData.append('Company', command.company.toString());
    uploadData.append('State', command.state);
    uploadData.append('RateType', command.rateType.toString());
    uploadData.append('Version', command.version);
    uploadData.append('Shown', command.shown.toString());
    return this.http.put<void>(`${this.api}commercial-auto-manuals/${command.id}`, uploadData);
  }
  /**
   * Get a commercial auto manual as a PDF by the id.
   * @param id
   */
  getCommercialAutoManualPdf(id: number): Observable<Blob> {
    return this.http.get<Blob>(`${this.api}commercial-auto-manuals/${id}`, this.pdfOptions);
  }
  /**
   * Delete a commercial auto manual.
   * @param id
   */
  deleteCommercialAutoManual(id: number): Observable<void> {
    return this.http.delete<void>(`${this.api}commercial-auto-manuals/${id}`);
  }
  /***********************************************************************
   * Flyers
   * Section
   **********************************************************************/
  /**
   * Get all of the flyers for the marketing page.
   */
  getFlyers(): Observable<MarketingMaterial[]> {
    return this.http.get<MarketingMaterial[]>(`${this.api}flyers`);
  }
  /**
   * Get a flyer by id.
   * @param id
   */
  getFlyerItem(id: number): Observable<MarketingMaterial> {
    return this.http.get<MarketingMaterial>(`${this.api}flyers/${id}`);
  }
  /**
   * Get a flyer icon by id.
   * @param id
   */
  getFlyerIcon(id: number): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(`${this.api}flyers/${id}/icon`, { observe: 'response', responseType: 'blob' as 'json' });
  }
  /**
   * Get a flyer pdf by id.
   * @param id
   */
  getFlyerPdf(id: number, withHeaders: boolean): Observable<any> {
    if (withHeaders)
      return this.http.get<Blob>(`${this.api}flyers/${id}/pdf`, { observe: 'response', responseType: 'blob' as 'json' });
    return this.http.get<Blob>(`${this.api}flyers/${id}/pdf`, { responseType: 'blob' as 'json' });
  }
    /**
   * Create a new flyer.
   * @param command
   */
    createFlyer(command: CreateMarketingMaterialCommand): Observable<void> {
      const uploadData = new FormData();
      uploadData.append('name', command.name);
      uploadData.append('effectiveDate', (command.effectiveDate.getMonth() + 1) + '/' + command.effectiveDate.getDate() + '/' + command.effectiveDate.getFullYear());
      uploadData.append('withdrawalDate', (command.withdrawalDate.getMonth() + 1) + '/' + command.withdrawalDate.getDate() + '/' + command.withdrawalDate.getFullYear());
      uploadData.append('showToBrokers', command.showToBrokers.toString());
      uploadData.append('pdfFile', command.pdfFile);
      uploadData.append('thumbnailFile', command.thumbnailFile);
     
      return this.http.post<void>(`${this.api}flyers`, uploadData);
    }
    /**
    * Edit an existing flyer.
    * @param command
    */
    editFlyer(command: EditMarketingMaterialCommand): Observable<void> {
      const uploadData = new FormData();
      uploadData.append('name', command.name);
      uploadData.append('effectiveDate', (command.effectiveDate.getMonth() + 1) + '/' + command.effectiveDate.getDate() + '/' + command.effectiveDate.getFullYear());
      uploadData.append('withdrawalDate', (command.withdrawalDate.getMonth() + 1) + '/' + command.withdrawalDate.getDate() + '/' + command.withdrawalDate.getFullYear());
      uploadData.append('showToBrokers', command.showToBrokers.toString());
      uploadData.append('pdfFile', command.pdfFile);
      uploadData.append('thumbnailFile', command.thumbnailFile);
  
      return this.http.put<void>(`${this.api}flyers/${command.id}`, uploadData);
    }
  /**
   * Delete a flyer by the id.
   * @param id
   */
  deleteFlyer(id: number): Observable<void> {
    return this.http.delete<void>(`${this.api}flyers/${id}`);
  }
    /***********************************************************************
   * Brochures
   * Section
   **********************************************************************/
  /**
   * Get all of the brochures.
   * Currently, there are only three so this is not a service call yet.
   */
  getBrochures(): Observable<MarketingMaterial[]> {
    return this.http.get<MarketingMaterial[]>(`${this.api}brochures`);
  }
  /**
   * Get a brochure by id.
   * @param id
   */
  getBrochureItem(id: number): Observable<MarketingMaterial> {
    return this.http.get<MarketingMaterial>(`${this.api}brochures/${id}`);
  }
  /**
   * Get a brochure icon.
   */
  getBrochureIcon(id: number): Observable<HttpResponse<Blob>> {
      return this.http.get<Blob>(`${this.api}brochures/${id}/icon`, { observe: 'response', responseType: 'blob' as 'json' });
  }
  /**
   * Get a brochure pdf by id.
   * @param id
   * The brochure id
   */
  getBrochurePdf(id: number, withHeaders: boolean): Observable<any> {
      if (withHeaders)
        return this.http.get<Blob>(`${this.api}brochures/${id}/pdf`, { observe: 'response', responseType: 'blob' as 'json' });
      return this.http.get<Blob>(`${this.api}brochures/${id}/pdf`, { responseType: 'blob' as 'json' });
  }
   /**
   * Create a new brochure.
   * @param command
   */
   createBrochure(command: CreateMarketingMaterialCommand): Observable<void> {
    const uploadData = new FormData();
    uploadData.append('name', command.name);
    uploadData.append('pdfFile', command.pdfFile);
    uploadData.append('thumbnailFile', command.thumbnailFile);
   
    return this.http.post<void>(`${this.api}brochures`, uploadData);
  }
  /**
  * Edit an existing brochure.
  * @param command
  */
  editBrochure(command: EditMarketingMaterialCommand): Observable<void> {
    const uploadData = new FormData();
    uploadData.append('name', command.name);
    uploadData.append('pdfFile', command.pdfFile);
    uploadData.append('thumbnailFile', command.thumbnailFile);

    return this.http.put<void>(`${this.api}brochures/${command.id}`, uploadData);
  }
  /**
   * Delete a brochure by the id.
   * @param id
   */
  deleteBrochure(id: number): Observable<void> {
    return this.http.delete<void>(`${this.api}brochures/${id}`);
  }
  /***********************************************************************
   * Emails
   * Section
   **********************************************************************/
  /**
   * Get all of the available emails.
   */
  getEmails(): Observable<Email[]> {
    return this.http.get<Email[]>(`${this.api}emails`);
  }
  /**
   * Request encrypted email communication.
   */
  requestEncryptedEmail(): Observable<void> {
    return this.http.post<void>(`${this.api}encrypted-email-requests`, null);
  }
  /**
   * Request training.
   * @param trainingRequest
   */
  requestTraining(trainingRequest: TrainingRequest): Observable<void> {
    return this.http.post<void>(`${this.api}training-request-emails/training`, trainingRequest);
  }
  /***********************************************************************
   * Companies
   * Section
   **********************************************************************/
  /**
   * Get a list of all the companies.
   * @param includeAll True if the 'All' company record should be included.
   */
  getCompanies(includeAll: boolean): Observable<Company[]> {
    return this.http.get<Company[]>(`${this.api}companies?includeAll=${includeAll}`);
  }
  /***********************************************************************
   * Bulletins
   * Section
   **********************************************************************/
  /**
   * Get a list of all of the bulletins.
   */
  getBulletins(): Observable<Bulletin[]> {
    return this.http.get<Bulletin[]>(`${this.api}bulletins`);
  }
  /**
   * Get a bulletin by the id.
   * @param id
   */
  getBulletin(id: number): Observable<Bulletin> {
    return this.http.get<Bulletin>(`${this.api}bulletins/${id}`).pipe(
      map(b => {
        b.date = new Date(b.date);
        return b;
      })
    );
  }
  /**
   * Get a PDF of the bulletin.
   * @param id
   */
  getBulletinPdf(id: number, documentId: number): Observable<Blob> {
    return this.http.get<Blob>(`${this.api}bulletins/${id}?documentId=${documentId ? documentId : null}`, this.pdfOptions);
  }
  /**
   * Create a new bulletin.
   * @param command
   */
  createBulletin(command: CreateBulletinCommand): Observable<void> {
    const uploadData = new FormData();
    uploadData.append('Code', command.code);
    uploadData.append('Name', command.name);
    uploadData.append('Date', (command.date.getMonth() + 1) + '/' + command.date.getDate() + '/' + command.date.getFullYear());
    for (let i = 0; i < command.states.length; i++) {
      uploadData.append(`States[${i}][state]`, command.states[i].state);
      uploadData.append(`States[${i}][withdrawn]`, command.states[i].withdrawn.toString());
    }
    command.documents.forEach(d => { uploadData.append('Documents', d); });
    return this.http.post<void>(`${this.api}bulletins`, uploadData);
  }
  /**
   * Edit an existing bulletin.
   * @param command
   */
  editBulletin(command: EditBulletinCommand): Observable<void> {
    const uploadData = new FormData();
    uploadData.append('Code', command.code);
    uploadData.append('Name', command.name);
    uploadData.append('Date', (command.date.getMonth() + 1) + '/' + command.date.getDate() + '/' + command.date.getFullYear());
    for (let i = 0; i < command.states.length; i++) {
      uploadData.append(`States[${i}][state]`, command.states[i].state);
      uploadData.append(`States[${i}][withdrawn]`, command.states[i].withdrawn.toString());
    }
    command.documents.forEach(d => { uploadData.append('Documents', d); });
    return this.http.put<void>(`${this.api}bulletins/${command.id}`, uploadData);
  }
  /**
   * Delete a bulletin by the id.
   * @param id
   */
  deleteBulletin(id: number): Observable<void> {
    return this.http.delete<void>(`${this.api}bulletins/${id}`);
  }
  /***********************************************************************
   * Workshop Materials
   * Section
   **********************************************************************/
   /**
   * Get the workshop materials PDF for the current (or most recent) year.
   */
  getWorkshopMaterialsPDF(): Observable<Blob> {
    return this.http.get<Blob>(`${this.api}workshopmaterials/Pdf`, this.pdfOptions);
  }
  /***********************************************************************
   * Applications
   * Section
   **********************************************************************/
  /**
   * Get a list of all of the available application forms.
   */
  getApplications(): Observable<Application[]> {
    return this.http.get<Application[]>(`${this.api}applications`);
  }
  /**
   * Download a pdf for the specified application id with the provided stamp.
   * @param id
   * @param stampRows
   */
  getApplicationPDF(applicationFormId: number, stamp: ApplicationStamp): Observable<Blob> {
    const queryString = stamp == null ? '' : Object.keys(stamp).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(stamp[key]);
    }).join('&');
    return this.http.get<Blob>(`${this.api}applications/${applicationFormId}?${queryString}`, this.pdfOptions);
  }
  /**
   * Get a single application by id.
   * @param id
   */
  getApplication(id: number): Observable<Application> {
    return this.http.get<Application>(`${this.api}applications/${id}`);
  }
  /**
   * Get a list of all the states and application ids for a provided form number and edition date.
   * @param formNumber
   * @param editionDate
   */
  getApplicationStates(formNumber: string, editionDate: string): Observable<ApplicationState[]> {
    return this.http.get<ApplicationState[]>(`${this.api}applications?formNumber=${formNumber}&editionDate=${encodeURIComponent(editionDate)}`);
  }
  /**
   * Create a new application.
   * @param command
   */
  createApplication(command: CreateApplicationCommand): Observable<void[]> {
    var batch: Observable<void>[] = [];
          
    for (let i = 0; i < command.states.length; i++) {
      const uploadData = new FormData();
      uploadData.append('FormNumber', command.formNumber);
      uploadData.append('FormDescr', command.description);
      uploadData.append('EdDate', (command.edition.getMonth() + 1) + '/' + command.edition.getDate() + '/' + command.edition.getFullYear());
      uploadData.append('Status', 'Live');
      uploadData.append('MultiState', command.states.length > 1 ? 'true' : 'false');
      uploadData.append('CoverageType', command.coverage);
      uploadData.append('StateAbbr', command.states[i]);
      uploadData.append('FormFile', command.documents[0]);
      batch.push(this.http.post<void>(`${this.api}applications`, uploadData));
    }
    return forkJoin(batch);
  }
  /**
   * Edit an existing application.
   * @param command
   * @param originalStates
   */
  editApplication(command: EditApplicationCommand, originalStates: ApplicationState[]): Observable<void[]> {
    var batch: Observable<void>[] = [];

    //Handle new and or updated states for the application.
    for (let i = 0; i < command.states.length; i++) {
      const uploadData = new FormData();
      uploadData.append('FormNumber', command.formNumber);
      uploadData.append('FormDescr', command.description);
      uploadData.append('EdDate', (command.edition.getMonth() + 1) + '/' + command.edition.getDate() + '/' + command.edition.getFullYear());
      uploadData.append('Status', 'Live');
      uploadData.append('MultiState', command.states.length > 1 ? 'true' : 'false');
      uploadData.append('CoverageType', command.coverage);
      uploadData.append('FormFile', command.documents[0]);
      uploadData.append('StateAbbr', command.states[i]);

      var previousApplications = originalStates.filter(s => s.state == command.states[i]);
      batch.push(this.http.put<void>(`${this.api}applications/${previousApplications[0].id}`, uploadData));
    }
    return forkJoin(batch);
  }
  /**
   * Delete a single application by id.
   * @param id
   * @param deleteFile
   */
  deleteApplication(id: number, deleteFile: boolean): Observable<void> {
    return this.http.delete<void>(`${this.api}applications/${id}?deleteFile=${deleteFile}`);
  }
  /**
   * Deletes an array of applications by id.
   * @param ids
   */
  deleteApplications(ids: number[]): Observable<void[]> {
    var batch: Observable<void>[] = [];
    ids.forEach(id => batch.push(this.deleteApplication(id, true)));
    return forkJoin(batch);
  }
  /***********************************************************************
   * Alerts
   * Section
   **********************************************************************/
  /**
   * Get new alerts.
   */
  getAlerts(): Observable<Alert[]> {
    return this.http.get<Alert[]>(`${this.api}alerts`);
  }
  /**
   * Add a new alert.
   * @param alert
   */
  addAlert(alert: Alert): Observable<Alert> {
    return this.http.post<Alert>(`${this.api}alerts`, alert);
  }
  /**
   * Update an alert.
   * @param id
   * @param alert
   */
  updateAlert(id: number, alert: Alert): Observable<Alert> {
    return this.http.put<Alert>(`${this.api}alerts/${id}`, alert);
  }
  /**
   * Delete an alert by the id.
   * @param id
   */
  deleteAlert(id: number): Observable<Alert> {
    return this.http.delete<Alert>(`${this.api}alerts/${id}`);
  }
  /***********************************************************************
   * UserAlerts
   * Section
   **********************************************************************/
  /**
   * Get alerts specific to a user.
   */
  getUserAlerts(): Observable<UserAlert[]> {
    return this.http.get<UserAlert[]>(`${environment.webAppsAPI}user-alerts`);
  }
  /**
   * Dismiss an alert so it is not shown again for this user.
   * @param id
   */
  dismissAlert(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.webAppsAPI}user-alerts/${id.toString()}`);
  }
  /***********************************************************************
   * HealthCheck
   * Section
   **********************************************************************/
  /**
   * Update data. Admin only and only on production.
   * TODO: Not a big fan of this...
   */
  updateData(): Observable<any> {
    return this.http.post(`${this.api}data-updates`, null);
  }
  /**
   * TODO: Fix this later.
   */
  getFormData(formData, data, previousKey) {
    if (data instanceof Object) {
      Object.keys(data).forEach(key => {
        const value = data[key];
        if (value instanceof Object && !Array.isArray(value)) {
          return this.getFormData(formData, value, key);
        }
        if (previousKey) {
          key = `${previousKey}[${key}]`;
        }
        if (Array.isArray(value)) {
          value.forEach(val => { formData.append(`${key}[]`, String(val)); });
        } else {
          formData.append(key, value);
        }
      });
    }
  }
  convertModelToFormData(model: any, form: FormData = null, namespace = ''): FormData {
    const formData = form || new FormData();
    let formKey;
    for (const propertyName in model) {
        if (!model.hasOwnProperty(propertyName) || !model[propertyName]) {
          continue;
        }
        if (namespace) {
          formKey = `${namespace}[${propertyName}]`;
        } else {
          formKey = propertyName;
        }
        if (model[propertyName] instanceof Date) {
          formData.append(formKey, model[propertyName].toISOString());
        } else if (model[propertyName] instanceof Array) {
          model[propertyName].forEach((element, index) => {
          const tempFormKey = `${formKey}[${index}]`;
          this.convertModelToFormData(element, formData, tempFormKey);
          });
        } else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)) {
          this.convertModelToFormData(model[propertyName], formData, formKey);
        } else {
          formData.append(formKey, model[propertyName].toString());
        }
    }
    return formData;
  }
  /**
   * Add a custom stamp.
   * @param customStamp The custom stamp to add.
   */
  addCustomStamp(customStamp : CustomStamp): Observable<CustomStamp> {
    const uploadData = new FormData();
    uploadData.append('customStamp[title]', customStamp.title);
    uploadData.append('customStamp[text]', customStamp.text);
    return this.http.post<CustomStamp>(`${this.api}custom-stamps`, uploadData);
  }
  /**
   * Update a custom stamp.
   * @param customStamp The custom stamp to update.
   */
  updateCustomStamp(customStamp: CustomStamp): Observable<void> {
    const uploadData = new FormData();
    uploadData.append('customStamp[id]', customStamp.id.toString());
    uploadData.append('customStamp[title]', customStamp.title);
    uploadData.append('customStamp[text]', customStamp.text);
    return this.http.put<void>(`${this.api}custom-stamps/${customStamp.id}`, uploadData);
  }
  /**
   * Delete the custom stamp with the given id.
   * @param id
   */
  deleteCustomStamp(id: number): Observable<CustomStamp> {
    return this.http.delete<CustomStamp>(`${this.api}custom-stamps/${id}`);
  }
  /**
   * Get the details of a single custom stamp.
   * @param id
   */
  getCustomStamp(id: number): Observable<CustomStamp> {
    return this.http.get<CustomStamp>(`${this.api}custom-stamps/${id}`);
  }
  /**
   * Get a list of all of the custom stamps for this user.
   */
  getCustomStamps(): Observable<CustomStamp[]> {
    return this.http.get<CustomStamp[]>(`${this.api}custom-stamps`);
  }
}
