import { Component } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent {
  
  constructor(
    private oauthService: OAuthService
  ) { }

  /**
   * Log out.
   */
  logout(): void {
      this.oauthService.revokeTokenAndLogout();
  }
}