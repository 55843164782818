import { Question } from "../services/custom/interfaces/question";

export const RecentlyBoundQuestions : Question[] = [
  {
    question: "What types of risks are shown?",
    answer: "Selected Commercial Auto and Garage new business binds issued electronically and final printed with the NICO software are shown."
  },
  {
    question: "What states are included?",
    answer: "All states where your agency has authority with NICO are included."
  },
  {
    question: "How is the number of units determined?",
    answer: "It is determined by the number of power units at inception. It does not include trailers."
  },
  {
    question: "How is the premium determined?",
    answer: "Premium is bound premium at inception."
  },
  {
    question: "How is the policy class determined?",
    answer: "Only one classification is counted per policy. If there are multiple classifications on a policy, the classification with the greatest amount of liability premium at inception is used."
  }
];