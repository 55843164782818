import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Report } from './interfaces/report';
import { User } from './interfaces/user';
import { ReportsAdminUserInfo } from './interfaces/reportsadminuserinfo';

@Injectable({
  providedIn: 'root'
})
export class DirectBillService {
  private api: string = '';
  constructor(
    private http: HttpClient
  ) {
    this.api = environment.directBillAPI;
  }

  /**
   * Get a static document as a PDF.
   * @param name 
   */
  getPDF(name: string): Observable<Blob> {
    return this.http.get<Blob>(`${this.api}documents/${name}`, { responseType: 'blob' as 'json' });
  }
  /**
   * Get all of the reports available to the current user.
   */
  getReports(agencyCode: string): Observable<Report[]> {
    // TODO: I don't think this logic should be in here. Defaults in the service maybe?
    var minDate = new Date();
    minDate.setMonth(minDate.getMonth() - 19);
    var maxDate = new Date();
    var query = `?minDate=${minDate.toLocaleDateString("en-US")}&maxDate=${maxDate.toLocaleDateString("en-US")}&groupMonths=true`;
    if (agencyCode && agencyCode !== '') {
      query += `&agencyCode=${agencyCode}`;
    }
    return this.http.get<Report[]>(`${this.api}commission-statements${query}`).pipe(
      map((value: Report[], index) => {
        if (value && value.length) {
          value.forEach(v => v.date = new Date(v.date));
            return value;
          }
        else {
          return [];
        }
      })
    );
  }
  /**
   * Download the actual report (PDF/CSV/XML).
   * @param id 
   * @param fileType 
   */
  getReport(id: string, fileType: string): Observable<Blob> {
    fileType = fileType.toLowerCase();
    return this.http.get<Blob>(`${this.api}commission-statements/${id}/${fileType}`, { responseType: 'blob' as 'json' });
  }
  /**
   * Get the direct bill user.
   */
  getUser(): Observable<User> {
    return this.http.get<User>(`${this.api}user`)
  }
  /**
   * 
   */
  getAllUsers(): Observable<ReportsAdminUserInfo[]> {
    return this.http.get<ReportsAdminUserInfo[]>(`${this.api}reports-admin/users`);
  }
}