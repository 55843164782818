import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { SubmissionsParams } from '../../../interactive-tools/models/submissions-params';
import { Submission } from '../../../interactive-tools/models/submission';

@Injectable()
export class SubmissionTrackingService {
  private api: string = '';
  constructor(
    private http: HttpClient
  ) {
    this.api = environment.submissionTrackingAPI;
  }

  /**
   * Get a list of all of the submissions for the
   * dashboard.
   */
  getSubmissions(params: SubmissionsParams): Observable<HttpResponse<Submission[]>> {
    return this.http.get<Submission[]>(`${this.api}submissions${params.toQueryString()}`, { observe: 'response' });
  }
  /**
   * Get a list of filters (all, closed, etc.). These are
   * just statically typed for now.
   */
  getSubmissionFilters(): string[] {
    return ['All', 'Closed', 'In Process', 'Bound', 'Expired'];
  }
}