import * as Sentry from '@sentry/browser';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

Sentry.init({
  dsn: 'https://3505c71473434c618f15cfd2150b9c3b@sentry.io/1195534',
  environment: environment.environment,
  debug: environment.environment === "Local",
  release: environment.version
});
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    if (environment.environment !== "Local") {
      Sentry.captureException(err);
    }
    else {
      console.log(err);
    }
  }
}