import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PoliciesParams } from '../../../interactive-tools/models/policies-params';
import { Policy } from '../../../interactive-tools/models/policy';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AcesService {
  private api: string = '';
  constructor(
    private http: HttpClient
  ) {
    this.api = environment.acesAPI;
  }

  /**
   * Get new policies (page/sort support).
   * @param params 
   */
  getNewBusinessBinds(params: PoliciesParams): Observable<HttpResponse<Policy[]>> {
    return this.http.get<Policy[]>(`${this.api}snapshots/new-business-binds${params.toQueryString()}`, { observe: 'response' });
  }
  /**
   * Get cancelled policies (page/sort support).
   * @param params 
   */
  getCancellations(params: PoliciesParams): Observable<HttpResponse<Policy[]>> {
    return this.http.get<Policy[]>(`${this.api}snapshots/cancellations${params.toQueryString()}`, { observe: 'response' });
  }
  /**
   * Get renewal policies (page/sort support).
   * @param params 
   */
  getRenewalBusinessBinds(params: PoliciesParams): Observable<HttpResponse<Policy[]>> {
    return this.http.get<Policy[]>(`${this.api}snapshots/renewal-business-binds${params.toQueryString()}`, { observe: 'response' });
  }
  /**
   * Get expring policies (page/sort support).
   * @param params 
   */
  getExpiring(params: PoliciesParams): Observable<HttpResponse<Policy[]>> {
    return this.http.get<Policy[]>(`${this.api}snapshots/expiring${params.toQueryString()}`, { observe: 'response' });
  }
  /**
   * Get a list of billing types that can be used to filter
   * the agencies. This is statically typed for now since
   * it shouldn't change. Currently unused.
   */
  getBillingTypes(): any[] {
    return [
      { name: 'Direct Bill', value: true },
      { name: 'Non-Direct Bill', value: false }
    ];
  }
}