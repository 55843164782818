import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, NO_ERRORS_SCHEMA, APP_INITIALIZER } from '@angular/core';

// Browser animations module for material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// IdentityServer and Okta
import { OAuthModule } from 'angular-oauth2-oidc';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthFailedInterceptor } from './interceptors/auth-failed.interceptor';
// Core and shared stuff
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { SharedModule } from './modules/shared/shared.module';
// Environment and startup
import { environment } from '../environments/environment';
import { StartupService } from './services/startup.service';
// Error Handling
import { SentryErrorHandler } from './error-handling/sentry-error-handler';

@NgModule({ declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA], imports: [BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        CoreModule,
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: environment.allowedUrls,
                sendAccessToken: environment.sendAccessToken
            }
        })], providers: [
        StartupService,
        {
            provide: APP_INITIALIZER,
            useFactory: (startupService: StartupService) => { return () => startupService.load(); },
            deps: [StartupService],
            multi: true
        },
        AuthFailedInterceptor,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthFailedInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
