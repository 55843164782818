import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value: string, args?: string): any {
    if (!value) {
      return value;
    }
    var cleaned = ('' + value).replace(/\D/g, '').replace(/\s/g, "").replace(/(?:\r\n|\r|\n)/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }
}