import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Routing
import { CoreRoutingModule } from './core-routing.module';
import { RouterModule } from '@angular/router';
// Components
import { NotFoundComponent } from './not-found/not-found.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
// Shared
import { SharedModule } from '../shared/shared.module';
// Services
import { AuthGuardService } from './guards/auth-guard.service';
// Simple Services (Generate or Manually mapped to Swagger docs)
import { AcesService } from './services/aces/aces.service';
import { DirectBillService } from './services/direct-bill/direct-bill.service';
import { FormsLibraryService } from './services/forms-library/forms-library.service';
import { LossRunsService } from './services/loss-runs/loss-runs.service';
import { SubmissionTrackingService } from './services/submission-tracking/submission-tracking.service';
import { WebAppsService } from './services/web-apps/web-apps.service';
// Other (custom usually)
import { UserService } from './services/custom/user.service';
import { StatesService } from './services/custom/states.service';

@NgModule({
  imports: [
    CommonModule,
    CoreRoutingModule,
    SharedModule
  ],
  declarations: [
    NotFoundComponent,
    NotAuthorizedComponent
  ],
  exports: [
    RouterModule
  ],
  providers: [
    // Simple Services,
    AcesService,
    DirectBillService,
    FormsLibraryService,
    LossRunsService,
    SubmissionTrackingService,
    WebAppsService,
    // Other (custom usually)
    StatesService,
    UserService
  ]
})
export class CoreModule { }
