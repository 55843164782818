export const environment = {
  production: (<any>window)._environment.production,
  environment: (<any>window)._environment.environment,
  featureFlagRelay: (<any>window)._environment.featureFlagRelay,
  launchDarklyClientId: (<any>window)._environment.launchDarklyClientId,
  webAppsAPI: (<any>window)._environment.webAppsAPI,
  formsLibraryAPI: (<any>window)._environment.formsLibraryAPI,
  nrEngineAPI: (<any>window)._environment.nrEngineAPI,
  amsAPI: (<any>window)._environment.amsAPI,
  lossRunsAPI: (<any>window)._environment.lossRunsAPI,
  directBillAPI: (<any>window)._environment.directBillAPI,
  submissionTrackingAPI: (<any>window)._environment.submissionTrackingAPI,
  acesAPI: (<any>window)._environment.acesAPI,
  appBasePath: (<any>window)._environment.appBasePath,
  authConfig: {
    issuer: (<any>window)._environment.authConfig.issuer,
    redirectUri: (<any>window)._environment.authConfig.redirectUri,
    postLogoutRedirectUri: (<any>window)._environment.authConfig.postLogoutRedirectUri,
    silentRefreshRedirectUri: (<any>window)._environment.authConfig.silentRefreshRedirectUri,
    clientId: 'Nico.BusinessPartners.Application',
    scope: 'openid profile role Nico.BusinessPartners.API Nico.WebApps.API Nico.FormsLibrary.API Nico.AgencyManagementSystem.API Nico.LossRuns.API Nico.DirectBill.API Nico.NREngine.API Nico.SubmissionTracking.API Nico.ACES.API',
    showDebugInformation: false,
    sessionChecksEnabled: true,
    oidc: true,
    requireHttps: true
  },
  oktaConfig: {
    clientId: (<any>window)._environment.oktaConfig.clientId,
    issuer: (<any>window)._environment.oktaConfig.issuer,
    redirectUri: (<any>window)._environment.oktaConfig.redirectUri,
    postLogoutRedirectUri: (<any>window)._environment.oktaConfig.postLogoutRedirectUri,
    scope: (<any>window)._environment.oktaConfig.scope,
    showDebugInformation: false,
    oidc: true,
    requireHttps: true,
    responseType: 'code'
  },
  allowedUrls: (<any>window)._environment.allowedUrls,
  sendAccessToken: true,
  scorecardLink: (<any>window)._environment.scorecardLink,
  glassLink: (<any>window)._environment.glassLink,
  mypLink: (<any>window)._environment.mypLink,
  version: require('../../package.json').version,
  ChangePasswordURL: (<any>window)._environment.ChangePasswordURL,
  OktaChangePasswordURL: (<any>window)._environment.OktaChangePasswordURL
};
