import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Policy } from './interfaces/policy';

@Injectable({
  providedIn: 'root'
})
export class LossRunsService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Get the loss runs pdf.
   * @param polNum 
   * @param homeOffice 
   */
  getPdf(polNum: string, homeOffice: boolean): Observable<Blob> {
    var url = `${environment.lossRunsAPI}print/${polNum}?homeOffice=${homeOffice}`;
    return this.http.get<Blob>(url, { responseType: 'blob' as 'json' });
  }
  /**
   * Get the URL to search/autocomplete policies by insured name.
   */
  searchInsured(insured: string): Observable<Policy[]> {
    return this.http.get<Policy[]>(environment.lossRunsAPI + 'search?insuredName=' + insured);
  }
  /**
   * Get the URL to search/autocomplete policies by number.
   */
  searchPolicy(policyNum: string): Observable<Policy[]> {
    return this.http.get<Policy[]>(environment.lossRunsAPI + 'search?policyNumber=' + policyNum);
  }
}