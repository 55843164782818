import { Question } from "../services/custom/interfaces/question";

export const TopClassesQuestions: Question[] = [
  {
    question: "What types of accounts are included?",
    answer: "Selected classes that show a significant increase over the past six months are included."
  },
  {
    question: "How is the policy class determined?",
    answer: "Only one classification is counted per policy. If there are multiple classifications on a policy, the classification with the greatest amount of liability premium at inception is used."
  }
];