import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { initialize, LDClient, LDFlagChangeset, LDFlagSet, LDUser } from "launchdarkly-js-client-sdk";
import { ReplaySubject } from "rxjs";
import { environment } from "../../../environments/environment";
import { DefaultLaunchDarklyModel, LaunchDarklyModel } from "./launch-darkly.model";

export const LaunchDarklyClient = new InjectionToken<LDClient>("Launch Darkly Client", {
  providedIn: "root",
  factory: () => {
    try {
      return initialize(environment.launchDarklyClientId, { key: "temporary-user", email: "", anonymous: true }, {
        baseUrl:environment.featureFlagRelay,
        eventsUrl:environment.featureFlagRelay,
        streamUrl:environment.featureFlagRelay
      });
    } catch (error) {
      return null;
    }
  }
});

@Injectable({
  providedIn: "root"
})
export class LaunchDarklyService {
  private initializedSubject$ = new ReplaySubject<boolean>(1);
  public initialized$ = this.initializedSubject$.asObservable();
  public launchDarklyModel = DefaultLaunchDarklyModel();

  constructor(@Optional() @Inject(LaunchDarklyClient) private ldClient: LDClient) {
    if (ldClient) {
      this.ldClient.on("change", flags => this.updateFlags(flags));
      this.ldClient.on("ready", () => this.setInitialFlags());
    } else {
      this.emitInitialized();
    }
  }

  private setInitialFlags() {
    const flags = this.ldClient.allFlags();
    this.initializeFlags(flags);
    this.emitInitialized();
  }

  private emitInitialized() {
    this.initializedSubject$.next(true);
    this.initializedSubject$.complete();
  }

  /**
   * Set the current user.
   * @param user LDUser
   */
  public async setUser(user: LDUser) {
    try {
      await this.ldClient.identify(user ? user : { key: "temporary-user" });
    } catch {
      return;
    }
    (window as any).ldClient = this.ldClient;
    (window as any).ldInit = initialize;
  }

  public initializeFlags(flags: LDFlagSet) {
    this.launchDarklyModel = this.updateState((flagName, currentValue) => this.tryGetFlagFromSet(flags, flagName, currentValue));
  }

  private updateFlags(flags: LDFlagChangeset) {
    this.launchDarklyModel = this.updateState((flagName, currentValue) => this.tryGetFlagFromChangeset(flags, flagName, currentValue));
  }

  public updateState(flagGetter: (flagName: string, val: boolean) => boolean): LaunchDarklyModel {
    const state = this.launchDarklyModel;

    return {
      feature110694addEditDeleteApplications: flagGetter("feature-110694-addeditdelete-applications", state.feature110694addEditDeleteApplications),
      feature112673administrativepagelink: flagGetter("feature-112673-administrative-page-link", state.feature112673administrativepagelink)
    };
  }

  public tryGetFlagFromChangeset(flags: LDFlagChangeset, key: string, original: boolean): boolean {
    const flag = flags[key];
    if (flag !== undefined && flag.current !== undefined) {
      return flag.current as boolean;
    }
    return original;
  }

  public tryGetFlagFromSet(flags: LDFlagSet, key: string, original: boolean): boolean {
    const flag = flags[key];
    if (flag !== undefined) {
      return flag as boolean;
    }
    return original;
  }

}
