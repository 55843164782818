import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'login/callback',
    redirectTo: 'home'
  },
  {
    path: 'home',
    canActivate: [AuthGuardService],
    loadChildren: () => import('../home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'contact',
    canActivate: [AuthGuardService],
    loadChildren: () => import('../contact-us/contact-us.module').then(m => m.ContactUsModule)
  },
  {
    path: 'tools',
    canActivate: [AuthGuardService],
    loadChildren: () => import('../interactive-tools/interactive-tools.module').then(m => m.InteractiveToolsModule)
  },
  {
    path: 'underwriting',
    canActivate: [AuthGuardService],
    loadChildren: () => import('../underwriting-resources/underwriting-resources.module').then(m => m.UnderwritingResourcesModule)
  },
  {
    path: 'more',
    canActivate: [AuthGuardService],
    loadChildren: () => import('../write-more/write-more.module').then(m => m.WriteMoreModule)
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
})],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
