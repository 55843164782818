import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Prefix } from './interfaces/prefix';
import { Form } from './interfaces/form';

@Injectable({
  providedIn: 'root'
})
export class FormsLibraryService {
  private api: string = '';
  constructor(
    private http: HttpClient
  ) {
    this.api = environment.formsLibraryAPI;
  }

  /**
   * Get a list of all of the coverage forms.
   */
  getCoverageForms(): Observable<Form[]> {
    return this.http.get<Form[]>(`${this.api}forms`)
      .pipe(map(
        (res: Form[]) => {
          for (var i = 0; i < res.length; i++) {
            res[i].edition = new Date(res[i].edition);
            res[i].withdrawn = new Date(res[i].withdrawn);
          }
          return res;
        }));
  }
  /**
   * Get a PDF url for the coverage form.
   * @param id 
   */
  getCoverageFormPdf(id: number, fillable: boolean): Observable<Blob> {
    return this.http.get<Blob>(`${this.api}forms/${id}/pdf${fillable ? '?fillable=true' : ''}`, { responseType: 'blob' as 'json' });
  }
  /**
   * Get the list of prefixes that are available for filtering
   * the coverage forms.
   */
  getCoverageFormPrefixes(): Observable<Prefix[]> {
    return this.http.get<Prefix[]>(`${this.api}forms/prefixes`);
  }
}