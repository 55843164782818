import { Injectable, inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { filter } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../../environments/environment';
import { LaunchDarklyService } from '../../../services/launch-darkly/launch-darkly.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
class PermissionsService  {

  constructor(
    private router: Router,
    private oauthService: OAuthService,
    private launchDarklyService: LaunchDarklyService
  ) { }

  /**
   * Check if they are logged in with identity server.
   * Check if they have access to this app.
   */
 canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

    let isInitialized$ = this.launchDarklyService.initialized$.pipe(
      filter((is: boolean) => !!is)
    );

    isInitialized$.subscribe((isInitialized) => {
      if (!isInitialized) return;

      let hasValidIdToken = this.oauthService.hasValidIdToken();
      let hasValidAccessToken = this.oauthService.hasValidAccessToken();

      if (hasValidIdToken && hasValidAccessToken) return true;

      this.oauthService.configure(environment.oktaConfig);

      this.oauthService.loadDiscoveryDocumentAndLogin().then(() => {
        if (this.oauthService.hasValidIdToken() || this.oauthService.hasValidAccessToken()) {
          this.oauthService.setupAutomaticSilentRefresh();
          return true;
        }
        else {
          this.router.navigate(['/']);
          return false;
        }
      }, (error: any) => {
        // Do not display errors here due to inline token hook failure
        console.log(`Found error in auth-guard loadDiscoveryDocumentAndLogin ${JSON.stringify(error)}`);
        if (error = 'access_denied') {
          this.redirectToNotAuthorizedPage()
          return false;
        }
      });
    }, (error: any) => {
      // Do not display errors here due to inline token hook failure
    });

    return isInitialized$;
  }
  /**
   * Redirect them to let them know that their account is not
   * authorized for this app.
   */
  redirectToNotAuthorizedPage() {
    this.router.navigate(['/not-authorized']);
  }
}

export const AuthGuardService: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> => {
  return inject(PermissionsService).canActivate(next, state);
}