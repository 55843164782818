import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { environment } from '../../environments/environment';
import { UserService } from '../modules/core/services/custom/user.service';
import { LaunchDarklyService } from './launch-darkly/launch-darkly.service';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class StartupService {

  constructor(
    private http: HttpClient,
    private oauthService: OAuthService,
    private userService: UserService,
    private launchDarklyService: LaunchDarklyService
  ) { }

  /**
   * This should all be loaded before the app loads. This
   * promise will be resolved before the app does anything
   * else.
   */
  load(): Promise<void> {
    this.loadLaunchDarkly();

    let isInitialized$ = this.launchDarklyService.initialized$.pipe(
      filter((is: boolean) => !!is)
    );

    isInitialized$.subscribe((isInitialized) => {
      if (!isInitialized) return;

      this.oauthService.configure(environment.oktaConfig);
      if (this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken()) {
        this.oauthService.setupAutomaticSilentRefresh();
      }

      return new Promise<void>((resolve) => resolve());
    });

    return new Promise<void>((resolve) => resolve());
  }

  loadLaunchDarkly() {
    this.launchDarklyService.initialized$.toPromise();
    return true;
  }
}
