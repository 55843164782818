import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { LaunchDarklyService } from './services/launch-darkly/launch-darkly.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Business Partners';
  routerSubscription;
  subscriptions: Subscription[] = [];
  isLoadingBannerHidden: boolean = false;
  idToken: string = 'id_token';
  accessToken: string = 'access_token';
  /**
   * Construct the component.
   * @param router
   */
  constructor(
    private router: Router,
    private oauthService: OAuthService,
    private launchDarklyService: LaunchDarklyService
  ) { }
  /**
   * Initialize the component. Redirect to any previous URLs and make
   * sure scrolling returns to the top of the page when needed.
   */
  ngOnInit(): void {

    let isInitialized$ = this.launchDarklyService.initialized$.pipe(
      filter((is: boolean) => !!is)
    );

    isInitialized$.subscribe((isInitialized) => {
      if (!isInitialized) return;

      this.oauthService.configure(environment.oktaConfig);

      let claims = this.oauthService.getIdentityClaims();
      let isLoggedIn = this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken() && claims['agencies'];
      let isUnauthorized = window.location.pathname === '/not-authorized';
      this.isLoadingBannerHidden = isLoggedIn || isUnauthorized;

      this.subscriptions.push(
        this.router.events
          .pipe(filter(event => event instanceof NavigationEnd))
          .subscribe(event => {
            window.scrollTo(0, 0);
            let isUnauthorized = window.location.pathname === '/not-authorized';
            if (isUnauthorized) this.isLoadingBannerHidden = true;
          }),
          this.oauthService.events.pipe(filter(e => e.type === 'token_received')).subscribe(e => {
            let claims = this.oauthService.getIdentityClaims();
            if (claims['agencies'] != null) {
              this.isLoadingBannerHidden = this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken() && claims['agencies'];
              if (this.isLoadingBannerHidden) return;
            }
            else {
              if (window.sessionStorage.getItem('attempts') === null){
                window.sessionStorage.setItem('attempts', '3');
              }
              let attempts: number = parseInt(window.sessionStorage.getItem('attempts'));
              if (attempts > 0) {
                window.sessionStorage.removeItem(this.accessToken);
                window.sessionStorage.removeItem(this.idToken);
                attempts--;
                window.sessionStorage.setItem('attempts', attempts.toString());
                this.oauthService.loadDiscoveryDocumentAndLogin();
              }
              else this.router.navigate(['/not-authorized']);
            }
          })
      );
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', 'UA-345326-7',
            {
              'page_path': event.urlAfterRedirects
            }
          );
        }
      })
    });
  }
  /**
   * Clean up before destroying the component, usually to prevent
   * memory leaks.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
