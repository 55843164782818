import { Injectable } from '@angular/core';
import { State } from './interfaces/state';
import { of, Observable } from 'rxjs';
import { States } from '../../data/states';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StatesService {

  constructor() { }

  /**
   * Get an array of all the states. Uses rxjs 'of' for now in case
   * this turns into an API call later.
   */
  getAllStates(includeNationwide: boolean): Observable<State[]> {
    return of(States).pipe(
      map(states => states.filter(s => includeNationwide || s.abbreviation !== 'NW'))
    );
  }
}