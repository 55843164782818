<div class="app-bootstrap" *ngIf="!isLoadingBannerHidden">
  <i aria-hidden="true" class="icon icon-logo"></i>
  <div class="progress">
    <div></div>
  </div>
  <p>Loading Business Partners</p>
</div>
<div *ngIf="isLoadingBannerHidden">
  <router-outlet></router-outlet>
</div>
