import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { LaunchDarklyService } from '../services/launch-darkly/launch-darkly.service';

@Injectable()
export class AuthFailedInterceptor implements HttpInterceptor {
  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private launchDarklyService: LaunchDarklyService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(event => {
        // Nothing to do here
      }, error => {
        if (error.status === 401) {
          let isInitialized$ = this.launchDarklyService.initialized$.pipe(
            filter((is: boolean) => !!is)
          );

          isInitialized$.subscribe((isInitialized) => {
            if (!isInitialized) return;

            let isLoggedIn = this.oauthService.hasValidAccessToken();
            if (!isLoggedIn) return;

            window.sessionStorage.clear();
            this.oauthService.loadDiscoveryDocumentAndLogin();
          });
        }
        else if (error.status === 403) {
          this.router.navigate(['/not-authorized']);
        }
      })
    );
  }
}
