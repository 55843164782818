import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Router
import { RouterModule } from '@angular/router';
// Forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Material Design Drag Drop
import { DragDropModule } from '@angular/cdk/drag-drop';
// Material Design Form Controls
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
//import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// Material Design Navigation
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
//import { MatToolbarModule } from '@angular/material/toolbar';
// Material Design Layout
//import { MatCardModule } from '@angular/material/card';
//import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
//import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
//import { MatStepperModule } from '@angular/material/stepper';
//import { MatTabsModule } from '@angular/material/tabs';
// Material Buttons & Indicators
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
//import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
// Material Popups & Modals
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
// Material Data Table
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
// Components
import { IconCardComponent } from './components/icon-card/icon-card.component';
import { DarkHeaderContainerComponent } from './page-templates/dark-header-container/dark-header-container.component';
import { SearchContainerComponent } from './page-templates/search-container/search-container.component';
import { IconLinkComponent } from './components/icon-link/icon-link.component';
import { ImageTitleCardComponent } from './components/image-title-card/image-title-card.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { AnimatedCheckmarkComponent } from './components/animated-checkmark/animated-checkmark.component';
import { AnimatedXComponent } from './components/animated-x/animated-x.component';
import { LoadingTextComponent } from './components/loading-text/loading-text.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { PdfInputComponent } from './components/pdf-input/pdf-input.component';
import { ImageInputComponent } from './components/image-input/image-input.component';
import { ButtonSpinnerComponent } from './components/button-spinner/button-spinner.component';
// Page, header, footer, sidebar
import { HeaderComponent } from './page-templates/header/header.component';
import { FooterComponent } from './page-templates/footer/footer.component';
import { PageComponent } from './page-templates/page/page.component';
import { SideMenuComponent } from './page-templates/side-menu/side-menu.component';
// Pipes
import { BooleanYesNoPipe } from './pipes/boolean-yes-no.pipe';
import { PhonePipe } from './pipes/phone.pipe';
// Directives
import { AutofocusDirective } from './directives/autofocus.directive';
import { PhoneMaskDirective } from './directives/phonemask.directive';
// Modals
import { VinModalComponent } from './modals/vin-modal/vin-modal.component';
import { DataDetailsModalComponent } from './modals/data-details-modal/data-details-modal.component';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import { SuccessModalComponent } from './modals/success-modal/success-modal.component';
import { FileDownloadModalComponent } from './modals/file-download-modal/file-download-modal.component';
import { GlassModalComponent } from './modals/glass-modal/glass-modal.component';
import { LoadingModalComponent } from './modals/loading-modal/loading-modal.component';
// Bottom Sheets
import { FileChooserComponent } from './components/file-chooser/file-chooser.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DragDropModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        //MatSliderModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatSidenavModule,
        //MatToolbarModule,
        //MatCardModule,
        //MatDividerModule,
        MatExpansionModule,
        //MatGridListModule,
        MatListModule,
        //MatStepperModule,
        //MatTabsModule,
        MatButtonModule,
        MatButtonToggleModule,
        //MatChipsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatBottomSheetModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        IconCardComponent,
        DarkHeaderContainerComponent,
        SearchContainerComponent,
        IconLinkComponent,
        ImageTitleCardComponent,
        PaginationComponent,
        HeaderComponent,
        FooterComponent,
        PageComponent,
        BooleanYesNoPipe,
        SideMenuComponent,
        AnimatedCheckmarkComponent,
        AnimatedXComponent,
        VinModalComponent,
        GlassModalComponent,
        LoadingModalComponent,
        LoadingTextComponent,
        SnackbarComponent,
        PhonePipe,
        PdfInputComponent,
        ImageInputComponent,
        ButtonSpinnerComponent,
        AutofocusDirective,
        PhoneMaskDirective,
        DataDetailsModalComponent,
        SuccessModalComponent,
        ErrorModalComponent,
        FileDownloadModalComponent,
        FileChooserComponent
    ],
    exports: [
        IconCardComponent,
        DarkHeaderContainerComponent,
        DragDropModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        //MatSliderModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatSidenavModule,
        //MatToolbarModule,
        //MatCardModule,
        //MatDividerModule,
        MatExpansionModule,
        //MatGridListModule,
        MatListModule,
        //MatStepperModule,
        //MatTabsModule,
        MatButtonModule,
        MatButtonToggleModule,
        //MatChipsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatBottomSheetModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        FormsModule,
        ReactiveFormsModule,
        SearchContainerComponent,
        IconLinkComponent,
        ImageTitleCardComponent,
        PaginationComponent,
        PageComponent,
        BooleanYesNoPipe,
        AnimatedCheckmarkComponent,
        AnimatedXComponent,
        LoadingTextComponent,
        PhonePipe,
        PdfInputComponent,
        ImageInputComponent,
        ButtonSpinnerComponent,
        AutofocusDirective,
        PhoneMaskDirective
    ],
    providers: [
        PhonePipe
    ]
})
export class SharedModule { }